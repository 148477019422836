<template>
  <!-- Multiple Choice Submission -->
  <div>
    <!-- Multiple Choice Submission Card Header -->
    <b-card
      bg-variant="transparent"
      class="material-card border-light-secondary mb-1"
    >
      <div class="d-flex">
        <div class="mr-1">
          <b-avatar rounded="lg" variant="light-success">
            <i class="card-icon ti ti-list-check" />
          </b-avatar>
        </div>
        <div class="d-flex flex-column">
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column ">
              <span class="text-muted ">Pilihan Ganda</span>

              <h6 class="font-weight-bolder small-gap-bottom">
                Tugas : {{ taskData.task.name }}
              </h6>
            </div>
          </div>

          <b-card-text>
            {{ taskData.task.description }}
          </b-card-text>
        </div>
      </div>
    </b-card>

    <!-- Tempate Quiz -->
    <div class="my-1 d-flex justify-content-end">
      <b-button class="mr-1" variant="success" @click="downloadTemplate">
        <i class="ti ti-download" />
        Download Template
      </b-button>

      <input
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ref="file"
        type="file"
        style="display: none"
        @change="importQuestion"
      />

      <b-button variant="warning" @click="$refs.file.click()">
        <i class="ti ti-table-import" />
        Import Soal
      </b-button>
    </div>

    <!-- Multiple Choice -->
    <b-card
      v-for="(quiz, quizIndex) in allQuiz"
      :key="quizIndex"
      bg-variant="transparent"
      class="material-card border-light-secondary p-1 mb-1 "
    >
      <b-row>
        <b-col md="12">
          <!-- Exam Detail -->
          <div v-if="false" class="d-flex justify-content-between mb-2">
            <!-- Teacher Info -->
            <div>
              <h6 class="font-weight-bolder small-gap-bottom">
                Guru Kelas
              </h6>
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="32"
                    src="/img/13-small.d796bffd.png"
                    text="avatarText('Bambang')"
                    variant="`light-primary`"
                  />
                </template>
                <span class="font-weight-bold d-block text-nowrap">
                  Sukmadi, S.Pd.
                </span>
                <small class="text-muted">NIP. 1404591288123</small>
              </b-media>
            </div>

            <!-- Class Info -->
            <div>
              <h6 class="font-weight-bolder small-gap-bottom">
                Kelas
              </h6>
              <span>
                Geografi
              </span>
            </div>

            <!-- Progress Info -->
            <div class="w-30">
              <div class="d-flex justify-content-between">
                <h6 class="font-weight-bolder small-gap-bottom">
                  Progress
                </h6>
                <h6 class="font-weight-bolder small-gap-bottom">
                  05:00 Menit / 2 Jam
                </h6>
              </div>
              <b-progress class="small-gap-top" value="2" max="100" />
            </div>
          </div>

          <!-- Exam Content -->
          <div>
            <h6 class="font-weight-bolder small-gap-bottom">
              Pertanyaan
            </h6>

            <!-- Exam Question -->
            <div class="d-flex mb-1">
              <b-avatar class="small-gap-right" variant="primary">
                {{ quizIndex + 1 }}
              </b-avatar>
              <!-- <p class="font-weight-normal">
                {{ quiz.question }}
              </p> -->
              <b-form-textarea
                v-model="quiz.question"
                no-resize
                placeholder="Masukkan Pertanyaan"
              />
            </div>

            <!-- Exam Answer -->
            <div class="exam-answer-options">
              <div
                v-for="(option, index) in quiz.options"
                :key="option.id"
                class="d-flex "
              >
                <b-form-group class="w-100 small-gap-right">
                  <b-input-group class="small-gap-bottom">
                    <b-input-group-prepend is-text>
                      <b-form-radio
                        v-model="quiz.answer"
                        :value="option.id"
                        plain
                      />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="option.text"
                      @click="quiz.answer = option.id"
                    />
                  </b-input-group>
                </b-form-group>

                <b-button
                  class="delete-button"
                  variant="outline-danger"
                  @click="deleteOption(quizIndex, index)"
                >
                  <i class="ti ti-trash"></i>
                </b-button>
              </div>
            </div>

            <!-- Exam Answer Add -->
            <div class="d-flex justify-content-start">
              <b-button
                class="small-gap-right"
                variant="outline-warning"
                @click="addOption(quizIndex)"
              >
                <i class="ti ti-plus"></i>
                Tambahkan Opsi Jawaban
              </b-button>

              <b-button
                variant="outline-danger"
                @click="deleteQuestion(quizIndex)"
              >
                <i class="ti ti-trash"></i>
                Hapus Soal
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col>
        <b-button class="w-100" variant="outline-primary" @click="addQuestion">
          <i class="ti ti-plus" />
          Tambah Soal
        </b-button>
      </b-col>
    </b-row>

    <b-col md="12" class="border-top pt-1 my-1">
      <div class="d-flex justify-content-end">
        <b-button variant="primary" class="ml-1" @click="submitForm">
          <b-spinner
            v-if="componentLoading"
            class="mr-1"
            variant="light"
            small
          />
          <i
            v-if="!componentLoading"
            class="align-middle ti ti-device-floppy small-gap-right"
          />
          <span class="align-middle ">Simpan</span>
        </b-button>
      </div>
    </b-col>
  </div>
</template>

<script>
import client from '@/libs/http/axios-config'
import { checkAuthorizeRole } from '@/auth/utils'

import {
  BBadge,
  BRow,
  BCol,
  BCard,
  BButton,
  BCardText,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  BFormRadio,
  BInputGroupPrepend,
  BAvatar,
  BMedia,
  BProgress,
  BFormGroup,
  BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import quizExample from './quizMultiplechoiceExample'
import { read, utils, writeFile } from 'xlsx'

export default {
  components: {
    BBadge,
    BRow,
    BCol,
    BCard,
    BButton,
    BCardText,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BFormRadio,
    BInputGroupPrepend,
    BAvatar,
    BMedia,
    BProgress,
    BFormGroup,
    BSpinner,
  },
  data() {
    return {
      // state
      isLoading: true,
      componentLoading: false,

      // default data
      classroomId: this.$router.currentRoute.params.id,
      contentId: this.$router.currentRoute.params.contentId,
      taskId: this.$router.currentRoute.params.taskId,
      taskData: {},
      // Quiz Data
      allQuiz: [
        {
          questionNumber: 1,
          questionId: 1,
          question: '',
          options: [
            {
              id: 1,
              text: '',
            },
            {
              id: 2,
              text: '',
            },
          ],
          lastOptionIndex: 2,
          answer: null,
        },
      ],
    }
  },
  created() {
    this.taskData = JSON.parse(localStorage.getItem('taskData'))
  },
  methods: {
    addOption(quizIndex) {
      this.allQuiz[quizIndex].options.push({
        id: this.allQuiz[quizIndex].lastOptionIndex + 1,
        text: '',
      })

      this.allQuiz[quizIndex].lastOptionIndex += 1
    },
    deleteOption(quizIndex, index) {
      this.allQuiz[quizIndex].options.splice(index, 1)
    },
    addQuestion() {
      this.allQuiz.push({
        questionNumber: this.allQuiz.length + 1,
        question: '',
        options: [
          {
            id: 1,
            text: 'Jawaban Benar',
          },
          {
            id: 2,
            text: '',
          },
        ],
        lastOptionIndex: 2,
        answer: 1,
      })
    },
    deleteQuestion(quizIndex) {
      this.allQuiz.splice(quizIndex, 1)
    },
    generateQuizNumber() {
      this.allQuiz.forEach((quiz, index) => {
        quiz.questionNumber = index + 1
      })
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },

    convertQuizToJson() {
      const quizJson = {
        questions: [],
      }

      this.allQuiz.forEach((quiz) => {
        // add isAnswered
        quiz.options.forEach((option) => {
          option.option = option.text
          delete quiz.text

          if (option.id === quiz.answer) {
            option.is_correct = true
          } else {
            option.is_correct = false
          }
        })

        const questionObject = {
          question_number: quiz.questionNumber,
          question: quiz.question,
          explanation: '-',
          type: this.taskData.quizType,
          options: quiz.options,
        }

        quizJson.questions.push(questionObject)
        console.log(quizJson)
      })

      return quizJson
    },

    async submitForm() {
      const url = `/teachers/classrooms/${this.classroomId}/class-contents/${this.contentId}/tasks/${this.taskId}/exams`

      this.componentLoading = true

      this.generateQuizNumber()
      const data = this.convertQuizToJson()

      await client
        .post(url, data)
        .then((response) => {
          // show success toast
          this.showToast(
            'success',
            'CheckIcon',
            'Berhasil',
            'Tugas berhasil ditambah'
          )

          // delete local storage data
          // localStorage.removeItem('taskData')
          this.componentLoading = false

          // redirect to classroom detail
          this.$router.push({
            name: checkAuthorizeRole('classroom-detail-exam'),
            params: { id: this.classroomId, contentId: this.contentId },
          })
        })
        .catch((error) => {
          this.componentLoading = false
          console.error(error)
        })
    },

    async importQuestion(e) {
      const file = e.target.files[0]
      console.log(file)

      const ab = await file.arrayBuffer()

      /* parse workbook */
      const wb = read(ab)

      /* update data */
      const json = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]])

      let questionData = []
      let idIndex

      // mapping into question data
      json.map((row) => {
        if (row.questionNumber) {
          idIndex = 1
          questionData.push(row)
          questionData[questionData.length - 1].options = [
            {
              id: idIndex,
              text: row.options,
            },
          ]
        } else {
          idIndex += 1
          questionData[questionData.length - 1].options.push({
            id: idIndex,
            text: row.options,
          })
        }
      })

      // add answer id
      questionData.map((row) => {
        row.lastOptionIndex = row.options[row.options.length - 1].id

        const answerId = row.options.find((option) => {
          return option.text === row.answer
        }).id

        row.answer = answerId
      })

      this.allQuiz = questionData
    },

    downloadTemplate() {
      window.location = '/template/Quiz_Multiplechoice_Template.xlsx'
    },
  },
}
</script>

<style lang="scss" scoped>
.round-button {
  padding: 0.2%;
  margin: 0.7%;
  font-size: 0.7rem;
  height: 2.5rem;
  width: 2.5rem;
}

.w-30 {
  width: 30%;
}

.exam-answer-options .form-control[readonly] {
  cursor: pointer;
  background-color: white !important;
}

.exam-answer-options .delete-button {
  height: 2.714rem;
}
</style>
